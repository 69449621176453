@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

body{
    margin:0px;
    padding: 0px;
    background-color: #000000;
    font-family: 'Montserrat', sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *{
    box-sizing: border-box;
  }
  ul{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
  button{
    outline: none;
    border: none;
  }
  input{
    outline: none;
    border: none;
  }
  select {
    display: block;
    width: -webkit-fill-available;
    padding: 0.5rem;
    border-radius: 0.25rem;
}
  .logo img{
    
    height:70px;
    position:fixed;
    left: 10px;
    z-index:1000;
    
  }
  #main{
    display:grid;
    grid-template-rows:10% 30% 60%;
    width:100%;
    /* height:800px; */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }
  nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  z-index: 1;
  background-color: #0f0f0f;
  }
  nav ul{
  display: flex;
  
  }
  .active{
    background-color: #d0ff00;
    color:#000000;
  box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
  transition: all ease 0.2s;
  }
  nav ul li a{
    font-family: calibri;
  height:40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  color:#ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
  }
  .hey{
    font-family: calibri;
  color:#ffffff;
  font-weight: 500;
  font-size: 0.9rem;
  border-bottom: 2px solid #000266;
  }
  nav ul li a:hover{
  background-color: #d0ff00;
    color:#000000;
  box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
  transition: all ease 0.2s;
  }
  nav .menu-btn,
  .menu-icon{
    display:none;
  }
  .name{
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
    left: 50%;
    text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
  }
  .name span{
    color: #d0ff00;
    border-bottom: 2px solid #d0ff00;
  }
  .name .details{
    color: #e6dfdf;
  }
  .name h1{
  font-family:'Montserrat';
  font-size: 45px;
  margin:0px;
  letter-spacing: 3px;
  color:#ffffff;
 
  }
  .name h3{
    font-family:'Montserrat';
    font-size:20px;
    margin:0px;
    letter-spacing:1px;
    color:#ffffff
  }
  .header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 6.8em;
  }
  .cv-btn{
  width:110px;
  height: 40px;
  display: flex;
  justify-content: center;
    align-items: center;
    background-color: #d0ff00;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
    border-radius: 10px;
  color:#000266;
  }
  .cv-btn1{
    font-size: 14px;
  width:140px;
  height: 40px;
  display: flex;
  justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #ffffff;
    color:#ffffff;
    border-radius: 10px;
    margin-left: 20px;
  }
  .cv-btn:hover{
  background-color: #ffffff;
    transition: all ease 0.5s;
    color: #000266;
  }
  .cv-btn1:hover{
  background-color: #ffffff;
    transition: all ease 0.5s;
    color: #000266;
  }
  
  .arrow{
    align-self: end;
    width: 50%;
    height: 15%;
    margin-bottom: 4em;
    position: -webkit-sticky;
    top:200px;
    bottom:1px;
    border-right: 2px solid  #d0ff00;
    animation: arrow-animation ease 1.5s;
  
  }
  .arrow::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 11px 0px 11px;
    border-color: #d0ff00 transparent transparent transparent;
    right: -0.7em;
    bottom: -2px;
  }
  @keyframes arrow-animation{
    0%{
        bottom: 70px;
        opacity: 0.2;
    }
    100%{
        bottom: 5px;
        opacity: 1;
    }
  }
  .f-heading h1{
    color:#f1e20c;
    font-size: 2.5rem;
    margin: 0px;
    padding: 0px;
  
  }
  .f-heading p{
    color:rgba(243, 243, 243, 0.678);
    font-size: 1rem;
    margin-top: 10px;
    padding: 0px;
  }
  .f-heading{
    text-align: center;
    margin-top: 60px;
    margin-bottom: -120px;
  }
.App > video{
  object-fit: cover;
  min-height: 50vh;
}
#company{
  
  margin-top: auto;
  width:100%;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px ;
}
.company h1{
  color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;
}
 #customers{
  margin-top: 120px;
  width:100%;
  min-height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
 }
  .c-heading h1{
    color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;
  }
    .c-heading p{
      color:#c6e708ad;
      font-size: 1rem;
      margin: 0px;
      padding: 0px;
    }
    .c-box{
      background-color: #ffffff;
      /* width:350px; La anchura será según la resolución */
      width: 1fr;
      /* height: 470px; Le quitamos la altura para que se aplique automáticamente*/
      margin: 20px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
      position: relative;
      box-sizing: content-box;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
    .c-box:hover{
      transform:translateY(-10px);
      transition: all ease 0.3s;
    
    }
    .c-box:h1{
      text-align: center;
      padding: 0;
    }
    #btnLang{
      background:transparent;
      float: right;
    }
  #services{
    margin-top: 120px;
    width:100%;
    min-height:100vh;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
  }
  
  .s-heading h1{
    color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;
  
  }
  .s-heading p{
    color:rgba(193, 245, 7, 0.678); 
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
  }
  .s-heading{
    text-align: center;
    margin: 20px 0px;
  }
  .s-box{
    background-color: #ffffff;
    width:350px;
    height: 470px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
    position: relative;
  }
  
  .b-container{
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: 90%;
  
  }
  .s-b-img{
    width: 100%;
  
  }
  .s-b-img img{
    display: block;
    width: 100%;
    height: 20vh;
    object-fit: cover;
  
  }
  .s-b-text{
    width: 100%;
    background-color:#ffffff;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 1rem;
  }
  .s-b-text p{
    margin: 0px;
    color:#000266a6;
    font-size: 1.1rem;
    font-family: calibri;
    display: block;
    display: -webkit-box;
    /* max-width: 80%; */
    margin-top: 2px;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
    
    text-overflow: ellipsis;
  }
  .s-b-text p:hover{
    color:#000266;
  }
  .s-b-text a{
    margin-top:15px ;
  }
  .s-type{
    font-family: calibri;
    color:#000266;
    background-color: #ffffff;
    padding: 10px 15px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
  }
  .s-box:hover{
    transform:translateY(-10px);
    transition: all ease 0.3s;
  
  }
  
  
 #canineadapted,
 #specialized{
    margin-top: auto;
    width:100%;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }  
  .v-box{
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 1rem;
      background-color: white;
      overflow: hidden;
      }
  .v-box h2{
    margin: unset;
    padding: 2rem;
    background: hsl(71deg 100% 50%);
    color: black;
  }
  #content  {
    margin-top: 120px;
    width:100%;
    min-height:100vh;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
  }   

.contact-wrapper{
  outline: 2px solid #222;
}

  #subscribe{
    background-color: #d0ff00;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    margin:10px 0px;
    margin-top: 80px;
    padding: 30px 0px;
    /* border-radius:50px; */
  }
  #subscribe h3{
  font-size:2.5rem;
  margin:20px;
  color: #000000;
  }
  .subscribe-input{
  width:400px;
  height:50px;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
  }
  .subscribe-input input{
  width:100%;
  background-color: transparent;
  border:none;
  outline: none;
  text-align: center;
  color:#242425;
  }
  .subscribe-input a{
  width:200px;
  height:35px;
  background-color:#d0ff00;
  color:#000000;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-in;
  }
  .subscribe-input a:hover{
    background-color:#000000;
  color:#d0ff00;
  }
  .subscribe-input a button{
    display: flex;
    background-color: transparent;
  }
  .subscribe-input a:hover button{
    color: #d0ff00;
  }


  @media(max-width:1190px){
  #main{
    background-size: 1150px !important;
    
  }
  .name{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .header-btns{
    margin-left: 10em;
  }
  
  .features-model img{
    height:400px;
    }
    #services{
    height:auto;
    }
    .b-container{
    flex-wrap: wrap;
  }
    .s-box{
    flex-grow: 1;
  }
  .s-b-img img{
    object-fit:cover;
  }
  
  }
  
  @media(max-width:970px){
    #main{
      background-size: 970px !important;
    }
    #services{
      margin-top: -60px;
    }
  .features-model{
    display: none;
  }
  .s-heading h1{
    font-size: 2.5rem;
    color: #d0ff00;
  }
  .features-text h2{
    font-weight: 600;
  }
  .b-container{
    margin-top: 30px;
    flex-direction: column;
  }
  .c-box,
  .v-box{
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
  #subscribe h3{
    font-size: 2.5rem;
  }
  #features{
    margin-top: -60px;
    justify-content: center;
    padding-top: 0px;
    font-size: 2rem;
    text-align: center;
  }
  .f-heading{
    display: none;
  }
  .features-text{
    width: 90%;
  }
  .features-text h1{
    font-size:4rem;
    }
  
  }

  
  @media(max-width:600px){
    #main{
      background-size: 600px !important;
      
    }
  .name{
    width:60%;
    }}
    .scroll{
        left: 42.5%;
    }
  @media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #d0ff00;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(158, 155, 0, 0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            /* padding:28px 20px; */
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#d0ff00;
            position: absolute;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:18px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:#d0ff00;
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:5px;
        }
        nav .menu-icon .nav-icon:after{
            top:-5px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
           
            
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .s-box{
                width: 100%;
                height:400px;
            }
        }
        @media(max-width:376px){
          .subscribe-input{
            width:300px;
            height:50px;
            }
            nav .menu-icon{
              /* position: absolute;
              margin-left: 34.5%; */
          }
          }
  