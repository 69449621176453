@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');


.content {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
}

ul {
  list-style: none;
  padding: 0;
}



.contact-wrapper {
  outline: 2px solid #222;
}

.contact-wrapper > * {
  padding: 1em;
}

.contact-form {
  background: #222222;
  color:#fff;
}
.contact-form form {
  display: block;
}
@media (min-width:971px) {
.contact-form form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
  
}

.contact-form form label {
  display: block;
}
.contact-from form .select{
  background:transparent;
  border: none;
  border-radius: 10;
  font-size: 14px;
  height: 30px;
  padding: 5px;
  width: 250px;  
}
select:focus{ outline: none;}
.contact-form form p {
  margin: 0;
  padding: 1em;
}

.contact-form form .block {
  grid-column: 1 / 3;
}

.contact-form form button,
.contact-form form input,
.contact-form form textarea {
  width: 100%;
  padding: .7em;
  border: none;
  background: none;
  outline: 0;
  color: #fff;
  border-bottom: 1px solid #d0ff00;
}

.contact-form form button {
  background: #d0ff00;
  color: #000;
  border: 0;
  text-transform: uppercase;
  padding: 1em;
  cursor: pointer;
}

.contact-form form button:hover,
.contact-form form button:focus {
  background: #a6cb00;
  transition: background-color 0.25s ease-in-out;
  outline: 0;
}

/* CONTACT INFO */
.contact-info {
  background: #000;
  color:#fff;
}

.contact-info h4, .contact-info ul, .contact-info p {
  text-align: center;
  margin: 0 0 1rem 0;
}

/* LARGE SIZE */
@media(min-width: 970px) {
  /* body { */
      /* padding: 0 4em; /* Paddingviejo que ya no va */
  /* } */
  .contact-wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
  }
  .contact-wrapper > * {
      padding: 2em;
  }
  .contact-info h4,
  .contact-info ul,
  .contact-info p {
      text-align: left;
  }
  
}
