    
.social{    
    position:fixed;
    left:0; 
    top:200px;
    z-index:3000;   
}
.social ul{ 
    list-style: none;
}
.social ul li a {
    display: inline-block;
    color:#fff;
    background:#000;
    padding:10px 15px;
    text-decoration: none;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; 
 }
.social ul li .fab fa-facebook-square{background:#3b5998;}
.social ul li .fab fa-instragram-square{background:#c32aa3;}
.social ul li .fab fa-youtube-square{background:#ff0000;}
.social ul li .fab fa-wathsapp-square{background: #25d366;}
.social ul li .fab fa-envelope-square{background: #666666;}
.social ul li .fas fa-share-alt-square{background: #25d366;}
.social ul li a:hover {padding: 10px 30px;
    background:#000;
    }